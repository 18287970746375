




import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import AppProgress from '@/components/Common/AppProgress.vue';
export default Vue.extend({
  data: () => ({
    syncInProgress: true,
    mapInProgress: false
  }),

  components: {
    AppProgress
  },
  async created() {
    await this.executeFullServerSync();
    this.syncInProgress = false;
    this.mapInProgress = true;
    await Promise.all([
      this.getAllDocuments(),
      this.getAllPictures(),
      this.getAllProducts(),
      this.getAllMeasurements(),
      this.getAllCoas(),
      this.getAllSuppliers()
    ]);
    this.mapInProgress = false;

    this.$router.push({ name: 'Documents' });
  },

  methods: {
    ...mapActions({
      executeFullServerSync: 'historyItems/executeFullServerSync',
      getAllDocuments: 'documents/getAll',
      getAllPictures: 'pictures/getAll',
      getAllProducts: 'products/getAll',
      getAllMeasurements: 'measurements/getAll',
      getAllCoas: 'coas/getAll',
      getAllSuppliers: 'suppliers/getAll'
    })
  }
});
