













import Vue from 'vue';
export default Vue.extend({
  mounted() {},
  props: ['isStoreInSync', 'storeName']
});
