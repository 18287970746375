



































import Vue from 'vue';
import { mapState, mapGetters, mapActions, Store } from 'vuex';
import StoreProgress from '@/components/Common/StoreProgress.vue';
export default Vue.extend({
  name: 'AppProgress',
  data: () => ({
    appName: process.env.VUE_APP_NAME
  }),
  props: ['syncInProgress', 'mapInProgress'],
  components: {
    StoreProgress
  },
  computed: {
    ...mapState({
      isProjectInSync: (state: any) => state.projects.isInSync,
      isDocumentInSync: (state: any) => state.documents.isInSync,
      isPicturesInSync: (state: any) => state.pictures.isInSync,
      isProductsInSync: (state: any) => state.products.isInSync,
      isMeasurementsInSync: (state: any) => state.measurements.isInSync,
      isChartOfAccountsInSync: (state: any) => state.coas.isInSync,
      isSuppliersInSync: (state: any) => state.suppliers.isInSync,
      isDocumentDraftsInSync: (state: any) => state.documentDrafts.isInSync
    }),
    animation() {
      return `${process.env.BASE_URL}img/animation-mq.gif`;
    }
  }
});
